body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

* {
  box-sizing: border-box;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
a {
  color: #3d6778;
}

strong {
  font-weight: 700;
}

.mobile {
  display: none;
}

@media(max-width: 900px) {
  .mobile {
    display: block;
  }
}

.desktop {
  display: none;
  @media(min-width: 901px) {
    display: flex;
    gap: 10px;
  }
}